import React from 'react';

import {
  PopupModal,
  ModalDialog,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '../../StyleComponents/pagesStyle';
import { Button, IconButton, Input, Field, Control } from '../../StyleComponents/styles';

const MemesPopup = ({
  title,
  close,
  handleImage,
  handleNewImage,
  urlValueImage,
  typeUrl,
  urlImageAdd,
}) => {
  return (
    <>
      <PopupModal
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <ModalDialog role="document">
          <ModalContent>
            <ModalHeader>
              <ModalTitle id="exampleModalCenterTitle">{title}</ModalTitle>

              <IconButton type="button" data-dismiss="modal" aria-label="Close" onClick={close}>
                <span aria-hidden="true"><strong>&#10006;</strong></span>
              </IconButton>
            </ModalHeader>

            <ModalBody>
              <h5>Background image:</h5>
              <label>
                <input type="file" onChange={handleImage} />
                <span>Choose a file…</span>
              </label>
              <hr />

              <h5>Extra image</h5>
              <label>
                <input type="file" onChange={handleNewImage} />
                <span>Choose a file…</span>
              </label>
              <hr />
              <h5>Link image</h5>
              <form onSubmit={urlImageAdd}>
                <Field>
                  <Control className="expandedWidth">
                    <Input
                      aria-label="urlImage"
                      type="text"
                      value={urlValueImage}
                      placeholder="Add Url Image"
                      onChange={typeUrl}
                      onSubmit={urlImageAdd}
                    />
                  </Control>
                  <Control>
                    <Button
                      borderColor="#1da1f2"
                      color="#1da1f2"
                      hoverColor="white"
                      hoverBorderColor="#1da1f2"
                      hoverBackgroundColor="#1da1f2"
                      onClick={urlImageAdd}
                    >
                      Add image
                    </Button>
                  </Control>
                </Field>
              </form>
            </ModalBody>

            <ModalFooter>
              <Button
                borderColor="#ea4336"
                color="white"
                backgroundColor="#ea4336"
                hoverBorderColor="#ea4336"
                hoverBackgroundColor="white"
                hoverColor="#ea4336"
                type="button"
                data-dismiss="modal"
                onClick={close}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalDialog>
      </PopupModal>
    </>
  );
};
export default React.memo(MemesPopup);
