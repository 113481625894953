import React, { useEffect, useRef, useState } from "react"
import { FaTintSlash } from "react-icons/fa"
import {
  FcAddImage,
  FcCancel,
  FcRemoveImage,
  FcDownload,
  FcEditImage,
  FcServices,
  FcAddRow,
  FcSignature,
} from "react-icons/fc"
import { fabric } from "fabric"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import MemesPopup from "../../../componentForPages/Popup/MemesPopup"
import {
  Title,
  SubTitle,
  Select,
  Button,
  Input,
  Field,
  Card,
  CardContent,
  Control,
  IconButton,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv, ColorImput } from "../../../StyleComponents/pagesStyle"

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/emoji-copy-and-paste",
  "/font-generator",
  "/html-entity",
  "/text-art",
]
const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Memes Generator",
    link: "/memes-generator/",
  },
]

function PopupEdit({
  backColor,
  backgroundColor,
  removeBackImageColor,
  // canvasWidth,
  // changeCanvasWidth,
  // canvasHeight,
  // changeCanvasHeight,
  close,
}) {
  return (
    <FlexDiv maxWidth="500px" justifyContent="center" margin="auto">
      <Card className="mb-2">
        <CardContent>
          <IconButton
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
            style={{ float: "right" }}
          >
            <span aria-hidden="true">
              <strong>&#10006;</strong>
            </span>
          </IconButton>
          <label htmlFor="back-color">Background color: </label>
          <ColorImput
            type="color"
            name="back-color"
            size="10"
            value={backColor}
            onChange={backgroundColor}
          />
          <IconButton onClick={removeBackImageColor}>
            <FcCancel size={26} />
          </IconButton>
        </CardContent>
        {/* Width x Height :
          <ul className="listul">
            <li>
              <label htmlFor="width">
                <input
                  className="input is-small widthAndHeight"
                  type="number"
                  value={canvasWidth}
                  onChange={changeCanvasWidth}
                  name="width"
                />
              </label>
            </li>
            <li>
              <FaTimes size={26} />
            </li>
            <li>
              <label htmlFor="Height">
                <input
                  type="number"
                  className="input is-small widthAndHeight"
                  value={canvasHeight}
                  onChange={changeCanvasHeight}
                  name="Height"
                />
              </label>
            </li>
          </ul> */}
      </Card>
    </FlexDiv>
  )
}

const Meme = props => {
  const canvasRef = useRef("")
  const canvas = useRef(null)
  let [hide, sethide] = useState(false)
  let [hideImage, sethideImage] = useState(false)
  let [hideEditImage, sethideEditImage] = useState(false)

  let [fontValue, setfontValue] = useState("Verdana")
  let [color, setColor] = useState("#FFFFFF")
  let [backColor, setbackColor] = useState("#FFFFFF")
  let [fontSize, setfontSize] = useState("26")
  // let [canvasWidth, setcanvasWidth] = useState("500")
  // let [canvasHeight, setcanvasHeight] = useState("437")
  let [fontStroke, setfontStroke] = useState("1")
  let [StrokeColor, setStrokeColor] = useState("#000000")
  let [textBackColor, settextBackColor] = useState("#000000")
  let [textAlignValue, settextAlignValue] = useState("left")
  let [fontStyle, setfontStyle] = useState("normal")
  let [message, setMessage] = useState("")
  let [urlValueImage, seturlValueImage] = useState("")
  let [textValueInput, settextValueInput] = useState("")
  let [fontSadow, setfontSadow] = useState("4")
  let [drawButtonText, setdrawButtonText] = useState("Enter drawing mode")
  let [draw, setdraw] = useState(false)
  let [drawWidth, setdrawWidth] = useState("5")
  let [drawColor, setdrawColor] = useState("#000000")

  // window.addEventListener("resize", adjustCanvasDimensions)

  function adjustCanvasDimensions() {
    // If an image was loaded this will be the image ratio (backstore ratio)
    const ratio = canvas.current.width / canvas.current.height
    const canvasContainer = document.querySelector(".fabric-canvas-wrapper")
    const containerWidth = canvasContainer.clientWidth

    const width =
      containerWidth < canvas.current.width
        ? containerWidth
        : canvas.current.width
    const height =
      containerWidth < canvas.current.width
        ? Math.floor(containerWidth / ratio)
        : canvas.current.height

    setCanvasCssDimensions({
      width: `${width}px`,
      height: `${height}px`,
    })
  }

  function setCanvasCssDimensions(dimensions) {
    return canvas.current.setDimensions(dimensions, { cssOnly: true })
  }
  function setCanvasBackstoreDimensions(dimensions) {
    return canvas.current.setDimensions(dimensions, { backstoreOnly: true })
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      canvas.current = new fabric.Canvas(canvasRef.current, {
        width: 700,
        height: 500,
        isDrawingMode: false,
      })
      let url = "https://i.imgflip.com/26am.jpg"
      const image = new Image()
      image.src = url
      image.crossOrigin = "anonymous"
      image.onload = function () {
        const photo = new fabric.Image(image)
        photo.set({
          left: 0,
          top: 0,
          objectCaching: false,
          selectable: false,
        })

        const width = image.width,
          height = image.height
        setCanvasBackstoreDimensions({ width, height })
        adjustCanvasDimensions()

        let text = new fabric.IText("hello world", {
          left: canvas.current.width / 3.5,
          top: 10,
          fontSize: 38,
          fontStyle: "bold",
          strokeWidth: 1,
          stroke: "black",
          fontFamily: "arial",
          fill: "white",
          curve: 20,
          shadow: "rgba(0,0,0,0.90) 3px 3px 3px",
          objectCaching: false,
        })
        // canvas.current.setWidth(image.width)
        // canvas.current.setHeight(image.height)
        // setcanvasWidth(image.width)
        // setcanvasHeight(image.height)
        canvas.current.add(photo, text)
      }
    }
  }, [])

  const changetextValueInput = e => {
    settextValueInput(e.target.value)
  }
  const openSettings = () => {
    hide ? sethide(false) : sethide(true)
  }
  const imageMenue = () => {
    hideImage ? sethideImage(false) : sethideImage(true)
  }

  const messageInTime = () => {
    setMessage("please select text to make a change")
    setTimeout(function () {
      setMessage("")
    }, 3000)
  }

  const textEditor = (e, set, attribute) => {
    if (canvas.current.getActiveObject()) {
      set(e.target.value)
      if (canvas.current.getActiveObject()._objects) {
        for (const a of canvas.current.getActiveObject()._objects) {
          a.set(attribute, e.target.value)
          canvas.current.requestRenderAll()
        }
      } else {
        canvas.current.getActiveObject().set(attribute, e.target.value)
        canvas.current.requestRenderAll()
      }
    } else {
      messageInTime()
    }
  }
  const anathorTextEditor = (e, set, attribute, val) => {
    if (canvas.current.getActiveObject()) {
      set(e)
      if (canvas.current.getActiveObject()._objects) {
        for (const a of canvas.current.getActiveObject()._objects) {
          a.set(attribute, val)
          canvas.current.requestRenderAll()
        }
      } else {
        canvas.current.getActiveObject().set(attribute, val)
        canvas.current.requestRenderAll()
      }
    } else {
      messageInTime()
    }
  }
  const clearSahow = e => {
    anathorTextEditor(0, setfontSadow, "shadow", "")
  }
  const changefontSadow = e => {
    anathorTextEditor(
      e.target.value,
      setfontSadow,
      "shadow",
      `rgba(0,0,0,0.90) ${e.target.value}px ${e.target.value}px ${e.target.value}px`
    )
  }
  function changeFont(e) {
    textEditor(e, setfontValue, "fontFamily")
  }
  const changeTextAlign = e => {
    textEditor(e, settextAlignValue, "textAlign")
  }
  const changeColor = e => {
    textEditor(e, setColor, "fill")
  }
  const changeontSize = e => {
    textEditor(e, setfontSize, "fontSize")
  }
  const textbackgroundColor = e => {
    textEditor(e, settextBackColor, "backgroundColor")
  }
  const removeBackTextColor = e => {
    if (canvas.current.getActiveObject()) {
      if (canvas.current.getActiveObject()._objects) {
        for (const a of canvas.current.getActiveObject()._objects) {
          a.set("backgroundColor", "")
          canvas.current.requestRenderAll()
        }
      } else {
        canvas.current.getActiveObject().set("backgroundColor", "")
        canvas.current.requestRenderAll()
      }
    } else {
      messageInTime()
    }
  }

  const removeBackImageColor = e => {
    canvas.current.set("backgroundColor", "transparent")
    canvas.current.requestRenderAll()
  }
  const backgroundColor = e => {
    setbackColor(e.target.value)
    canvas.current.set("backgroundColor", e.target.value)
    canvas.current.requestRenderAll()
  }
  // const changeCanvasWidth = e => {
  //   setcanvasWidth(e.target.value)
  //   canvas.current.setWidth(e.target.value)
  // }
  // const changeCanvasHeight = e => {
  //   setcanvasHeight(e.target.value)
  //   canvas.current.setHeight(e.target.value)
  // }

  const changeFontstroke = e => {
    textEditor(e, setfontStroke, "strokeWidth")
  }
  const changeStrokeColor = e => {
    textEditor(e, setStrokeColor, "stroke")
  }

  const changeFontStyle = e => {
    textEditor(e, setfontStyle, "fontStyle")
  }
  if (typeof window !== "undefined") {
    const deleteIcon =
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"
    var img = document.createElement("img")
    img.src = deleteIcon

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 16,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 24,
    })
  }
  function deleteObject(eventData, target) {
    let canvas = target.canvas
    if (target._objects) {
      for (const a of target._objects) {
        canvas.remove(a)
      }
    } else {
      canvas.remove(target)
    }
    canvas.requestRenderAll()
  }

  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    let size = this.cornerSize
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
    ctx.drawImage(img, -size / 2, -size / 2, size, size)
    ctx.restore()
  }

  function handleImage(e) {
    let reader = new FileReader()
    reader.onload = function (event) {
      let imgObj = new Image()
      imgObj.src = event.target.result
      imgObj.onload = function () {
        // start fabricJS stuff
        let image = new fabric.Image(imgObj)
        image.set({
          left: 0,
          top: 0,
          // width: imgObj.width,
          // height: imgObj.height,
          objectCaching: false,
          selectable: false,
        })
        const width = imgObj.width,
          height = imgObj.height
        setCanvasBackstoreDimensions({ width, height })
        adjustCanvasDimensions()
        // canvas.current.setWidth(imgObj.width)
        // canvas.current.setHeight(imgObj.height)
        // setcanvasWidth(imgObj.width)
        // setcanvasHeight(imgObj.height)
        canvas.current.add(image)
        // end fabricJS stuff
      }
    }
    reader.readAsDataURL(e.target.files[0])
    sethideImage(false)
  }
  const handleNewImage = e => {
    let reader = new FileReader()
    reader.onload = function (event) {
      let imgObj = new Image()
      imgObj.src = event.target.result
      imgObj.crossOrigin = "anonymous"
      imgObj.onload = function () {
        // start fabricJS stuff
        let image = new fabric.Image(imgObj)
        image.set({
          left: 0,
          top: 0,
          width: imgObj.width,
          height: imgObj.height,
          objectCaching: false,
        })
        canvas.current.add(image)
        // end fabricJS stuff
      }
    }
    reader.readAsDataURL(e.target.files[0])
    sethideImage(false)
  }

  const urlImageAdd = e => {
    e.preventDefault()
    // http://fabricjs.com/assets/pug_small.jpg
    if (urlValueImage.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      let image = urlValueImage
      //   image.crossOrigin = "anonymous"
      //   const url = "https://i.imgur.com/KxijB.jpg";
      const photo = new Image()
      photo.src = image
      photo.crossOrigin = "anonymous"
      photo.onload = function () {
        const ima = new fabric.Image(photo)
        ima.set({
          left: 0,
          top: 0,
          width: photo.width,
          height: photo.height,
          // selectable: false,
        })
        canvas.current.add(ima)
      }
    } else {
      setMessage("Please enter a valid image URL")
      setTimeout(function () {
        setMessage("")
      }, 3000)
    }
    sethideImage(false)
  }

  const addText = () => {
    let textValue = "Tap and Type"
    if (textValueInput !== "") {
      textValue = textValueInput
    }
    let text = new fabric.IText(textValue, {
      left: canvas.current.width / 3.5,
      top: 10,
      fontSize: 26,
      fontFamily: "arial",
      fontStyle: "bold",
      fill: "white",
      strokeWidth: 1,
      stroke: "black",
      objectCaching: false,
    })
    canvas.current.add(text)
    canvas.current.setActiveObject(text)
  }
  function download_image() {
    const a = document.createElement("a")
    a.href = canvasRef.current.toDataURL("image/png")
    a.download = "image.png"
    a.click()
    // document.getElementById("download").href = canvasRef.current.toDataURL(
    //   "image/png"
    // )
    // let img    = canvasRef.current.toDataURL("image/png");
    // document.write('<img src="'+img+'"/>');
  }
  const typeUrl = e => {
    e.preventDefault()
    seturlValueImage(e.target.value)
  }
  const clearCanvas = () => {
    canvas.current.clear()
    adjustCanvasDimensions()
  }

  const changeDrawColor = e => {
    setdrawColor(e.target.value)
    canvas.current.freeDrawingBrush.color = e.target.value
  }
  const changeDrawWidth = e => {
    setdrawWidth(e.target.value)
    canvas.current.freeDrawingBrush.width = parseInt(e.target.value, 10) || 1
  }

  const drawFunction = e => {
    if (canvas.current.isDrawingMode) {
      setdrawButtonText("Enter drawing mode")
      setdraw(false)
      canvas.current.isDrawingMode = false
    } else {
      setdrawButtonText("Cancel drawing mode")
      canvas.current.isDrawingMode = true
      setdraw(true)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Memes Generator"
        description="Create your memes image online, simply upload the image or paste the URL image then add text and edited, finally download the image."
        keywords={[
          "memes maker, online memes maker,memes without watermark,memes,make your memes ",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Memes Generator</Title>
        <SubTitle>Add text on a image online</SubTitle>
        <FlexDiv
          justifyContent="center"
          displayDiv="inline-flex"
          flexWrap="wrap"
        >
          <Button className="mb-2" onClick={imageMenue}>
            <FcAddImage size={26} /> Add image
          </Button>
          <Button
            className="mb-2"
            onClick={() =>
              hideEditImage ? sethideEditImage(false) : sethideEditImage(true)
            }
          >
            <FcEditImage size={26} /> Edit
          </Button>

          <Button
            // href="#"
            // id="download"
            className="mb-2"
            onClick={download_image}
            // download="image.png"
          >
            <FcDownload size={26} /> Save
          </Button>

          <Button className="mb-2" onClick={clearCanvas}>
            <FcRemoveImage size={26} /> Clear
          </Button>

          {hideImage && (
            <MemesPopup
              title="Add Image"
              handleImage={handleImage}
              handleNewImage={handleNewImage}
              urlImageAdd={urlImageAdd}
              urlValueImage={urlValueImage}
              typeUrl={typeUrl}
              close={() => sethideImage(false)}
            />
          )}
        </FlexDiv>
        {hideEditImage && (
          <PopupEdit
            backColor={backColor}
            backgroundColor={backgroundColor}
            removeBackImageColor={removeBackImageColor}
            // canvasWidth={canvasWidth}
            // changeCanvasWidth={changeCanvasWidth}
            // canvasHeight={canvasHeight}
            // changeCanvasHeight={changeCanvasHeight}
            close={() => sethideEditImage(false)}
          />
        )}
        <FlexDiv justifyContent="center">
          <FlexDiv
            maxWidth="100%"
            width="500px"
            margin="auto"
            className="fabric-canvas-wrapper"
          >
            <canvas
              ref={canvasRef}
              style={{ border: "1px solid black" }}
            ></canvas>
          </FlexDiv>
        </FlexDiv>
        <p style={{ textAlign: "center" }}>{message}</p>
        <br />
        <section>
          <FlexDiv
            justifyContent="center"
            displayDiv="inline-flex"
            flexWrap="wrap"
            margin="0 0 1rem 0"
          >
            <Field>
              <Control className="expandedWidth">
                <Input
                  name="text-input"
                  aria-label="textarea"
                  value={textValueInput}
                  onChange={changetextValueInput}
                />
              </Control>
              <Control>
                <Button onClick={addText}>
                  <FcAddRow size={28} /> add text
                </Button>
              </Control>
            </Field>

            {/* <label htmlFor="text-input">
                <textarea
                  name="text-input"
                  aria-label="textarea"
                  className="input"
                  value={textValueInput}
                  onChange={changetextValueInput}
                />
              </label>
              <button onClick={addText} className="button buttonTopclick">
                <FcAddRow size={28} /> add text
              </button> */}

            <ColorImput
              aria-label="color"
              type="color"
              name="text-color"
              size="10"
              className="mr-1"
              value={color}
              onChange={changeColor}
            />

            <ColorImput
              type="color"
              aria-label="stroke"
              className="ml-1"
              name="Stroke-color"
              size="10"
              value={StrokeColor}
              onChange={changeStrokeColor}
            />

            <Button onClick={openSettings}>
              <FcServices size={28} /> Edit Text
            </Button>
          </FlexDiv>
          <div>
            {hide ? (
              <FlexDiv maxWidth="500px" justifyContent="center" margin="auto">
                <Card className="mb-2">
                  <CardContent>
                    <Field>
                      <Control>
                        <StaticButton>Text Font</StaticButton>
                      </Control>
                      <Control>
                        <Select>
                          <select
                            className="staticSelect"
                            aria-label="font"
                            onChange={changeFont}
                            onBlur={changeFont}
                            value={fontValue}
                          >
                            <option value="arial"> Arial </option>
                            <option value="Verdana"> Verdana </option>
                            <option value="Pacifico"> Pacifico </option>
                            <option value="VT323"> VT323</option>
                            <option value="Quicksand"> Quicksand </option>
                            <option value="serif"> Serif </option>
                            <option value="monospace"> Monospace </option>
                            <option value="Inconsolata"> Inconsolata </option>
                          </select>
                        </Select>
                      </Control>
                    </Field>

                    <Field>
                      <Control>
                        <StaticButton>Text Style</StaticButton>
                      </Control>
                      <Control>
                        <Select>
                          <select
                            className="staticSelect"
                            aria-label="fontStyleText"
                            onChange={changeFontStyle}
                            onBlur={changeFontStyle}
                            value={fontStyle}
                          >
                            <option value="normal"> Normal </option>
                            <option value="oblique"> Oblique </option>
                            <option value="italic"> Italic</option>
                            <option value="bold"> Bold </option>
                            <option value="inherit"> Inherit </option>
                          </select>
                        </Select>
                      </Control>
                    </Field>

                    <div>
                      <label htmlFor="text-font-size">Font Size: </label>
                      <input
                        type="range"
                        min="12"
                        max="120"
                        step="1"
                        value={fontSize}
                        onChange={changeontSize}
                        name="text-font-size"
                      />
                    </div>
                    <div>
                      <label htmlFor="text-font-shadow">Shadow: </label>
                      <input
                        type="range"
                        min="0"
                        max="50"
                        step="1"
                        value={fontSadow}
                        onChange={changefontSadow}
                        name="text-font-shadow"
                      />
                      <IconButton onClick={clearSahow}>
                        <FcCancel size={28} />
                      </IconButton>
                    </div>
                    <div>
                      <label htmlFor="textback-color">Text Background: </label>
                      <ColorImput
                        type="color"
                        name="textback-color"
                        size="10"
                        value={textBackColor}
                        onChange={textbackgroundColor}
                      />
                      <IconButton onClick={removeBackTextColor}>
                        <FaTintSlash size={26} />
                      </IconButton>
                    </div>
                    <Field>
                      <Control>
                        <StaticButton>Text align</StaticButton>
                      </Control>
                      <Control>
                        <Select>
                          <select
                            className="staticSelect"
                            aria-label="text-align"
                            onChange={changeTextAlign}
                            onBlur={changeTextAlign}
                            value={textAlignValue}
                          >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                            <option value="justify">Justify</option>
                          </select>
                        </Select>
                      </Control>
                    </Field>
                    <Field>
                      <Control>
                        <StaticButton>Text Stroke</StaticButton>
                      </Control>
                      <Control className="expandedWidth">
                        <Input
                          radius="0"
                          className="borderedRight"
                          type="number"
                          min={0}
                          value={fontStroke}
                          onChange={changeFontstroke}
                          aria-label="text-font-Stroke"
                        />
                      </Control>
                    </Field>
                  </CardContent>
                </Card>
              </FlexDiv>
            ) : (
              ""
            )}
          </div>
          <FlexDiv justifyContent="center" style={{ textAlign: "center" }}>
            <Button id="drawing-mode" onClick={drawFunction} className="mb-2">
              <FcSignature size={28} /> {drawButtonText}
            </Button>
            {draw ? (
              <FlexDiv maxWidth="500px" justifyContent="center" margin="auto">
                <Card className="mb-2">
                  <CardContent>
                    {/* <label htmlFor="drawing-mode-selector">Mode:</label>
                  <select id="drawing-mode-selector">
                    <option>Pencil</option>
                    <option>Circle</option>
                    <option>Spray</option>
                    <option>Pattern</option>

                    <option>hline</option>
                    <option>vline</option>
                    <option>square</option>
                    <option>diamond</option>
                    <option>texture</option>
                  </select> */}
                    <p>To stop drawing click cancel</p>

                    <div>
                      <label htmlFor="drawing-line-width">Line width: </label>
                      <input
                        type="range"
                        value={drawWidth}
                        onChange={changeDrawWidth}
                        min="1"
                        max="50"
                        id="drawing-line-width"
                      />
                    </div>

                    <div>
                      <label htmlFor="drawing-color">Line color: </label>
                      <ColorImput
                        type="color"
                        value={drawColor}
                        onChange={changeDrawColor}
                        name="drawing-color"
                      />
                    </div>
                  </CardContent>
                </Card>
              </FlexDiv>
            ) : (
              ""
            )}
          </FlexDiv>
        </section>
        <br />
        <p>
          Create your memes image (memes without watermark) by upload a
          background image or extra image and colored the background, also you
          can make a change with the width and height of the image or cover,
          step two fix your images or image in the place you won't, step three
          add text by the button below, you cant type text in the input and
          added or you can add text and edited by a tap on the text and write,
          finally edit your text by selecting the text you want to edited and
          click on the button of "EDIT TEXT". You can also click on "Enter
          drawing mode" to start drawing on the image and click again to stoped.
          <br />
          This page lets you make memes photos online, add text on an image,
          draw on an image or edit images and text.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default Meme
